<template>
  <div class="page__wrapper">
    <div v-if="id" class="redirect">
      If you are not redirected automatically please <a :href="destination_url">click here</a>
    </div>
    <div v-else>
      <div class="logo__wrapper">
        <img src="./assets/logo.png" class="logo" alt="Awake Tomorrow Logo">
      </div>
      <div class="link__wrapper">
        <div class="link" v-for="item in links" :key="item.index">
          <a :href="item.link" target="_blank">{{ item.title }}</a>
        </div>
        <div class="link donate">
          <a href="https://awaketomorrow.org/donate" target="_blank">Donate</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { doc, updateDoc, increment, getDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { db, analytics } from "@/firebase";
export default {
  data() {
    return {
      links: [
        {
          title: "Our Website",
          link: "https://awaketomorrow.org"
        },
        {
          title: "Shop",
          link: "https://shop.awaketomorrow.org"
        },
        {
          title: "Testing Instructions",
          link: "https://awaketomorrow.org/assets/at-instructions-full.pdf"
        },
      ],
      id: "",
      destination_url: ""
    }
  },
  created() {
    this.redirectScan();
  },
  methods: {
    async redirectScan() {
      this.id = window.location.pathname.split('/')[1]
      if (this.id) {
        //log scan to firebase
        const docRef = doc(db, "qr_codes", this.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          this.destinatino_url = docSnap.data().destination_url
          updateDoc(docRef, {
            scans: increment(1),
          });
          console.log('scan +1')
          logEvent(analytics, "qr_scan", {
            qr_name: docSnap.data().name,
            qr_destination: docSnap.data().destination_url,
            qr_id: docSnap.id,
            filename: docSnap.data().filename,
            page_location: docSnap.data().short_url,
            page_title: docSnap.data().name
          })
          console.log('ga event +1')
          window.location.href = docSnap.data().destination_url;
        }
      }
    }
  },
};
</script>
<style>
html {
  background-color: #ff6432;
  color: #fff;
  width: 100%;
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.page__wrapper {
  padding: 3rem 1rem;

}
.logo__wrapper {
  padding-bottom: 3rem;
}

.logo {
  max-width: 300px;
  width: 100%;
  height: auto;
}

.link__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 300px;
  margin: 0 auto;
}
a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}
.link a {
  border: 1px solid #fff;
  color: #fff;
  display: block;
  text-transform: uppercase;
  border-radius: 50px;
  font-size: 1rem;
  padding: 14px 30px;
  font-family: Avenir, sans-serif;
  line-height: 1;
  transition: scale .2s ease;
  font-weight: 600;
}

.donate a {
  background-color: #fff;
  color: #ff6432
}

a:hover {
  scale: 1.05;
}
</style>